import StandardLayout from "../layouts/standard"
import React from "react"

export default function HealthAndEnvironment() {
  return (
    <StandardLayout title="Documents">
      <div
        className="title-container"
        style={{
          backgroundImage: `url(https://stylam-upload.s3.amazonaws.com/Group_17_2874670f6a.png)`,
          background: "gray",
        }}
      >
        Be IMPACTFUL.. <br />
        Be STYLAM
        <div>Ensuring conformity with STYLAM Standards</div>
      </div>

      <section className="uk-section">
        <div className="uk-container uk-margin">
          <div className="more-padding">
            <p className="health-p1">
              We, at Stylam, are constantly looking for ideas and solutions to
              repair our planet via our conscious efforts. With a view to
              continuous improvement, Stylam guarantees the lowest possible
              impact on the environment and plays its role in safeguarding
              well-being of all by contributing to social and environmental
              sustainability.
            </p>

            <p className="health-p2">
              Sustainability is at our heart. We showcase this through our
              manufacturing practises and intention to serve equally.
            </p>

            <div className="health-img1">
              <div className="health-img2">
                <img src={require("../images/health/Untitled-5.jpg").default} />
                <h2>ANTI-BACTERIAL</h2>
                <p>
                  At Stylam, all range is anti-bacterial. Antibacterial Laminate
                  is intended for application where there is a need for
                  additional protection of the surface against bacteria. A very
                  special resin formulation is applied over decorative surface
                  paper to achieve high degree of bacteria growth resistances.
                </p>

                <p>
                  We ensure protection against bacteria at no additional cost.
                </p>
              </div>

              <div>
                <img src={require("../images/health/Untitled-5.jpg").default} />

                <h2>ANTI-VIRAL CERTIFIED</h2>

                <p>
                  We take pride in creating Anti-viral laminate range. These
                  laminates have antiviral properties against specified virus
                  and safeguard surface from any viral activity.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-section lightgray-bg">
        <div className="uk-container uk-margin">
          <div className="more-padding">
            <p className="health-p4">
              We are an export house since 1991 and achieved accreditation from
              top authorities to meet International standards of production and
              quality.
            </p>

            <div className="ce-div">
              <div>
                <img src={require("../images/health/Untitled-7.jpg").default} />
              </div>
              <div className="ce-div-rev">
                <h3>CE LOGO</h3>
                <p>
                  CE marking ensures that a product has been assessed by the
                  manufacturer and deemed to meet EU safety, health and
                  environmental protection requirements. STYLAM proudly adhere
                  to CE marking since 2002.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-section">
        <div className="uk-container uk-margin">
          <div className="more-padding">
            <p className="health-p5">
              Stylam has received and maintains numerous certifications and
              credentials to ensure that our facilities continue to operate at a
              maximum level of performance and safety, and that our clients
              receive products and services of the highest possible quality.
            </p>

            <div className="health-certs">
              <div>
                <img src={require("../images/health/1.jpg").default} />
                <h4>ISO 9001: 2015 LOGO</h4>
                <p>
                  ISO 9001: 2015 guides the principles that can be used to
                  create efficiencies by aligning and streamlining processes
                  throughout STYLAM organization
                </p>
              </div>

              <div>
                <img src={require("../images/health/2.jpg").default} />
                <h4>ISO 45001 2018 LOGO</h4>
                <p>
                  STYLAM adhere to set of standards put forward by the
                  International Organization for Standardization (ISO). We aim
                  to reduce environmental footprint by adopting an effective
                  environmental management system (EMS)
                </p>
              </div>

              <div>
                <img src={require("../images/health/3.jpg").default} />
                <h4>ISO 14001 2015 LOGO</h4>
                <p>
                  ISO 14001 2015 ensures an environmental management system that
                  STYLAM organization uses to enhance its environmental
                  performance.
                </p>
              </div>
            </div>

            <div className="health-certs2">
              <div>
                <img
                  src={require("../images/health/Untitled-8aa.jpg").default}
                />

                <div className="health-certs2-div">
                  <h4>GRIHA LOGO</h4>
                  <p>
                    Stylam is a proud member of GRIHA and certifies that its
                    building meets certain nationally acceptable benchmarks.
                  </p>
                </div>
              </div>

              <div>
                <img
                  src={require("../images/health/Untitled-9ww.jpg").default}
                />

                <div className="health-certs2-div">
                  <h4>IGBC LOGO</h4>
                  <p>
                    ICBC ensures a holistic approach for STYLAM to create
                    environment friendly buildings, through architectural
                    design, water efficiency, effective handling of waste,
                    energy efficiency, sustainable buildings, and focus on
                    occupant comfort & well-being
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-section lightgray-bg">
        <div className="uk-container uk-margin">
          <div className="more-padding">
            <p className="health-p6">
              We share public health and safety values with all the realities
              that interface with us and which in turn become the spokespersons
              of an important and vital culture of protection for well-being of
              all.
            </p>

            <img src={require("../images/health/Untitled-9wq.jpg").default} />
            <h3>NSF LOGO</h3>
            <p className="health-p7">
              Stylam products are NSF International certified to protect and
              improve global human health. We ensure we contribute to help
              protect food, water, consumer products and the environment.
            </p>

            <div className="greenguard">
              <div>
                <img
                  src={require("../images/health/Untitled-9re.jpg").default}
                />
                <h3>GREENGUARD:</h3>
                <p>
                  UL 2818 - 2013 LOGO & Greenguard Gold: LOGO
                  <br />
                  <br />
                  Stylam meets all Standards for Chemical Emissions for Building
                  Materials, Finishes and Furnishings
                </p>
              </div>

              <div>
                <img
                  src={require("../images/health/Untitled-9-2.jpg").default}
                />
                <h3>GREEN LABEL LOGO</h3>
                <p>
                  We at Stylam, follow this practice of low emission and low
                  toxicity for improved environment
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </StandardLayout>
  )
}
